@import "fonts";

/* This file creates global CSS classes which can be reused in the backend - generated report HTML content. */

.global-menu-heading-font {
  font: $font-xl-regular;
}

.global-standard-bold-p-font {
  font: $font-default-bold;
}

.global-standard-p-font {
  font: $font-default-regular;
}
