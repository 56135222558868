@import 'src/app/common/styles/fonts';
@import 'src/app/common/styles/colors';
@import 'src/app/common/styles/functions';

.adm4-details-section {
  margin-bottom: default_spacing_multiplier(3);

  .adm4-section-title {
    border-bottom: 1px solid $line-grey;

    padding: default_spacing_multiplier(2) 0 default_spacing_multiplier(1);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;

    cursor: default;

    .help-icon {
      font-size: $font-size-2xl;
    }

    mat-icon {
      color: $menu-icon-grey;
    }

    .spacer {
      flex-grow: 1;
    }

    h1, h2, h3, h4 {
      font: $font-docs-h3;
      margin-right: default_spacing_multiplier(1.5);
    }
  }

  .adm4-section-content {
    padding: default_spacing_multiplier(2);
    background-color: white;
  }
}

.permission-scope-title {
  font: $font-l-regular;
  margin-bottom: default_spacing_multiplier(2);
  color: $menu-icon-grey;
}

.permission-scope-row {
  .permission-item {
    margin-left: default_spacing_multiplier(1);
  }
}
