div.monaco-hover div.hover-contents {
  hr {
    margin-bottom: 6px;
  }
}

/**
This sets the stale peekviews to have strikethrough text, because otherwise it's hard to tell which one is still active.
 */
.monaco-editor {
  .peekview-widget .stale .message {
    text-decoration: line-through;
  }
  .peekview-title .filename {
    display: none;
  }
}
