$dialog-margin-fullscreen: 50px;

.mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 5px !important;
  min-width: 500px !important;
  max-width: 95vw !important;
}

.mat-mdc-dialog-content {
  min-height: 80px !important;
  margin: 0 !important;
}

/**
`.mat-mdc-dialog-component-host` is added to the component that is rendered into the dialog,
 and it adds `display: contents` to the component, in order for the component's internal layout to be used.<br/>
 But in many cases, we designed the dialogs in a way where our top level component is part of the layout.<br/>
 So this restores that to 100% height block.
 ---
 Quote from the material source:

>    // When a component is passed into the dialog, the host element interrupts
>    // the `display:flex` from affecting the dialog title, content, and
>    // actions. To fix this, we make the component host `display: contents` by
>    // marking its host with the `mat-mdc-dialog-component-host` class.
>    //
>    // Note that this problem does not exist when a template ref is used since
>    // the title, contents, and actions are then nested directly under the
>    // dialog surface.
 */
.mat-mdc-dialog-component-host.adm4-override-mdc-dialog-component-host {
  display: block !important;
  height: 100% !important;
}

.mat-mdc-dialog-content.adm4-override-dialog-content-height {
  max-height: initial !important;
}

.big-dialog {
  margin: $dialog-margin-fullscreen !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 90%;
}

.dialog-h-400 {
  height: 400px;
}

.dialog-h-600 {
  height: 600px;
}

.dialog-h-700 {
  height: 700px;
}

.dialog-h-800 {
  height: 800px;
}

.dialog-h-1000 {
  height: 1000px;
}

.dialog-w-700 {
  width: 700px;
}

.dialog-w-800 {
  width: 800px;
}

.dialog-w-900 {
  width: 900px;
}

.dialog-w-1000 {
  width: 1000px;
}

.dialog-w-1100 {
  width: 1100px;
}

.dialog-w-1300 {
  width: 1300px;
}

.dialog-w-700,
.dialog-w-800,
.dialog-w-900,
.dialog-w-1000,
.dialog-w-1100,
.dialog-w-1300,
.dialog-w-allow-max,
.dialog-full-screen,
.dialog-global-search {
  --mat-dialog-container-max-width: 100vw;
}

.dialog-h-full {
  height: calc(100vh - $dialog-margin-fullscreen) !important;
}
.dialog-max-h-full {
  max-height: calc(100vh - $dialog-margin-fullscreen) !important;
}

// also might need `MatDialogConfig#maxWidth` and `MatDialogConfig#maxHeight` to be increased when opening the dialog
.dialog-full-screen {
  height: calc(100vh - $dialog-margin-fullscreen);
  width: calc(100vw - $dialog-margin-fullscreen);

  .mat-mdc-dialog-container {
    // the override is needed because the original global `max-width` is proportional, and is too big for this type of dialog
    // and the importants should be fixed, but the whole solution started out with them, so they are here for now
    max-width: unset !important;
  }
}

.dialog-global-search {
  width: 1100px;
  align-self: stretch;
  margin-top: 200px;
  margin-bottom: 200px;
}

.mat-mdc-dialog-container .mat-mdc-dialog-title+.mat-mdc-dialog-content.adm4-dialog-content {
  padding-top: default_spacing_multiplier(3);
  font: $font-default-regular;
  font-size: $font-size-normal;
  color: $screen-black;
}

mat-dialog-actions.adm4-dialog-actions {
  border-top: $components-border;
  padding-left: default_spacing_multiplier(3);
  padding-right: default_spacing_multiplier(3);
}
