@import "src/app/common/styles/colors";
@import "src/app/common/styles/fonts";
@import "src/app/common/styles/mixins";
@import "src/app/common/styles/functions";

/*  Toaster notification style   */
$toast-border-radius: 5px;
$toast-icon-spacing: default_spacing_multiplier(2);
$toast-icon-size: 40px;
$toast-text-spacing: default_spacing_multiplier(3);

.toast-top-right {
  top: 60px;
  right: 12px;
}

.toast-container {
  z-index: 101;
  font-size: $font-size-normal;
}

.nevis-notification {
  position: relative;
  border-radius: $toast-border-radius;
  padding: 0 0 0 ($toast-icon-size + $toast-icon-spacing * 2);
  margin: 10px;
  min-width: 400px;
  min-height: 100px;
  box-shadow: 0 0 12px #999999;
  border: 2px solid;
  display: flex;
  flex-flow: column;
  background-image: none; // remove background image that is added by toastr original styles
  pointer-events: auto;

  &:before {
    @include material-icon();
    font-size: 40px;
    position: absolute;
    transform: translate(0, -50%);
    left: $toast-icon-spacing;
    top: 50%;
  }

  & .toast-close-button {
    position: absolute;
    top: 0;
    right: 0.5em;
    color: $screen-black;
    opacity: 0.60;
    cursor: pointer;
    pointer-events: auto;
  }

  &.success {
    border-color: $validation-green;
    background-color: $light-validation-green;
    color: $validation-green;

    &:before {
      content: "done";
    }

    & .toast-close-button {
      color: $validation-green;
    }
  }

  &.warning {
    border-color: $warning-orange;
    background-color: $light-warning-orange;
    color: $warning-orange;

    &:before {
      content: "report_problem_outlined";
    }

    & .toast-close-button {
      color: $warning-orange;
    }
  }

  &.error {
    border-color: $error-red;
    background-color: $error-red;
    color: $light-error-red;

    &:before {
      content: "block_outlined";
    }

    & .toast-close-button {
      color: $error-red;
    }
  }

  &.info {
    border-color: $info-blue;
    background-color: $light-info-blue;
    color: $info-blue;

    &.info:before {
      content: "info_outlined";
    }

    & .toast-close-button {
      color: $info-blue;
    }
  }
}

.nevis-notification-title {
  border-top-right-radius: $toast-border-radius;
  padding: $toast-text-spacing $toast-text-spacing 0 $toast-text-spacing;
  background-color: $white-c0;
  font: $font-l-regular;

  &.success {
    color: $validation-green;
  }

  &.warning {
    color: $warning-orange;
  }

  &.error {
    color: $error-red;
  }

  &.info {
    color: $info-blue;
  }
}

.nevis-notification-message {
  background-color: $white-c0;
  border-top-right-radius: $toast-border-radius;
  border-bottom-right-radius: $toast-border-radius;
  color: $screen-black !important;
  padding: $toast-text-spacing;
  flex: 1;
}

// in case of has title -> remove the rounding
.nevis-notification-title + .nevis-notification-message {
  border-top-right-radius: 0;
  padding: default_spacing_multiplier(1) $toast-text-spacing $toast-text-spacing $toast-text-spacing;
}

.nevis-notification .toast-close-button:hover {
  opacity: 1;
}
