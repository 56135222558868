/* Many `rem` values were originally set when the base font size was `12px`,
but were adjusted when the base font size was changed to `16px`. */
$font-size-global-default: 16px; // defines size of 1 rem

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-sm: 0.6rem;
$font-size-normal: 0.75rem;
$font-size-l: 0.9rem;
$font-size-xl: 0.975rem;
$font-size-2xl: 1.05rem;
$font-size-3xl: 1.2rem;
$font-size-4xl: 1.35rem;
$font-size-5xl: 1.5rem;

$font-size-welcome-heading: 1.65rem;
$font-size-nav-header-logo: 1.725rem;
$font-size-login-heading: 1.8375rem;

$font_family-default: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$font-family-code: SFMono-Regular, Monaco, Menlo, Consolas, "Liberation Mono", "Courier New", monospace;

$font-docs-h1: 700 1.2rem $font_family-default;
$font-docs-h2: 700 1.05rem $font_family-default;
$font-docs-h3: 500 0.975rem $font_family-default;
$font-docs-h4: 400 0.9rem $font_family-default;
$font-docs-h5: 400 0.825rem $font_family-default;

$font-sm-regular: 400 $font-size-sm $font_family-default;

$font-default-regular: 400 $font-size-normal $font_family-default;
$font-default-bold: 700 $font-size-normal $font_family-default;

$font-l-regular: 400 $font-size-l $font_family-default;
$font-l-bold: 700 $font-size-l $font_family-default;

$font-xl-regular: 400 $font-size-xl $font_family-default;
$font-2xl-regular: 400 $font-size-2xl $font_family-default;
$font-2xl-bold: 700 $font-size-2xl $font_family-default;

$font-code-regular: 400 $font-size-normal $font-family-code;

$font-nav-big-header-logo: 400 $font-size-nav-header-logo $font_family-default;
$font-welcome-heading: 400 $font-size-welcome-heading $font_family-default;
$font-login-heading: 400 $font-size-login-heading $font_family-default;
