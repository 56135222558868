@import "colors";
@import "variables";
@import "fonts";
@import "functions";

/* mixins for media queries */
@mixin big-screen {
  @media #{$big-screen} {
    @content;
  }
}

/* end of mixins for media queries */

@mixin full-height-flex() {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Icon mixins */
@mixin material-icon() {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* End of icon mixins */

/* Button mixins */
@mixin admn4-button-ellipse() {
  font: $font-default-regular;
  line-height: 1;
  padding: calc($font-size-normal / 2) default_spacing_multiplier(2);
  border-radius: 12px;
}

@mixin admn4-button-text {
  font: $font-default-regular;
  line-height: 1;
  background: none;
  color: $menu-icon-grey;

  &[disabled] {
    color: $inactive-grey;
  }

  &:not([disabled]) {
    cursor: pointer;
  }
}

@mixin admn4-button-focus ($color) {
  outline-offset: $focus-outline-offset;
  outline: $color solid $focus-outline-width;
}

@mixin admn4-button-icon($color, $hover-color, $selected-color: $color) {
  display: flex;
  align-items: center;
  background: none;
  font-size: $default-icon-size;
  padding: 0;
  line-height: 1;
  color: $color;

  &:hover {
    color: $hover-color;
  }

  &.selected {
    color: $selected-color;
  }

  &[disabled] {
    color: $color;
  }

  &:focus {
    @include admn4-button-focus($color);
  }
}

/* End of button mixin */

// WARNING try not to re-include this one, but use one of the utility classes from `_util-classes.scss`, eg. `size-16`
@mixin mat-icon-size($size) {
  width: $size;
  height: $size;
  font-size: $size;
}

@mixin help-icon() {
  color: $inactive-grey;
  font-size: $help-icon-size;

  &.active, &:hover {
    color: $nevis-green-c1;
  }
}

@mixin left-line-indicator($color) {
  border-left: $left-line-indicator-width solid $color;
}

@mixin selected-list-item($color) {
  @include left-line-indicator($color);
}

@mixin hero-circle($size) {
  background-color: $white-c0;
  font-size: $size;
  color: $nevis-green-c1;
  border-radius: 50%;
}

@mixin content-height-minus($column-header-height, $editor-padding, $button-bar-height) {
  height: calc(100vh - #{$header-height} - #{$footer-height} - #{$column-header-height} - #{$editor-padding} - #{$editor-padding} - #{$button-bar-height});

  @include big-screen {
    height: calc(100vh - #{$header-big-height} - #{$footer-height} - #{$column-header-height} - #{$editor-padding} - #{$editor-padding} - #{$button-bar-height});
  }
}

//Applies the configured colors as left border on hover and selecting the element
//If none of them happens, creates an invisible border, intended for list elements which when selected should have border, invisible border is needed to keep content at the same place
@mixin line-indicatable($selected-color, $hover-color, $selected-background-color: unset, $hover-background-color: $selected-background-color) {
  @include left-line-indicator(transparent);

  &:hover {
    @include selected-list-item($hover-color);
    background-color: $hover-background-color;
  }

  &.selected {
    @include selected-list-item($selected-color);
    background-color: $selected-background-color;
  }

  &.batch-selected {
    &.selected {
      @include selected-list-item($selected-color);
      background-color: $nevis-green-light-c2;
    }
    &:not(.selected) {
      background-color: $nevis-green-ligtest-c4;

      &:hover {
        @include selected-list-item($selected-color);
        background-color: $nevis-green-ligtest-c4;
      }
    }
  }
}

@mixin line-indicatable-hoverable($hover-color) {
  @include left-line-indicator(transparent);

  &:hover {
    @include selected-list-item($hover-color);
  }
}

@mixin line-indicatable-selectable($selected-color) {
  @include left-line-indicator(transparent);

  &.selected {
    @include selected-list-item($selected-color);
  }
}

@mixin line-indicatable-with-box-shadow($selected-color, $hover-color, $selected-background-color: unset, $hover-background-color: $selected-background-color) {
  @include line-indicatable($selected-color, $hover-color, $selected-background-color, $hover-background-color);

  &.selected {
    box-shadow: 0 0 8px 2px $light-grey;
    z-index: 1;
  }
}

@mixin nevis-green-link() {
  color: $nevis-green-c1;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: $nevis-green-c1;
  }

  &:visited {
    text-decoration: none;
    color: $nevis-green-c1;
  }

  &[disabled] {
    color: $inactive-grey;
    pointer-events: none;
    text-decoration: none;
  }
}

@mixin colored-box-shadow($color) {
  box-shadow: 0 0 default_spacing_multiplier(1) 0 $color;
}

@mixin multiline-ellipsis($number-of-lines) {
  display: -webkit-box;
  -webkit-line-clamp: $number-of-lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
