@import "colors";

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

$default-padding-base: 8px; // base application padding, other paddings are multiplications of this value(there are exceptions)
$components-border: 1px solid $line-grey; // border around project screen components
$left-line-indicator-width: 5px; // indicator for list elements to show selected, errored etc elements
$default-input-border-radius: 3px;
$pattern-list-element-default-height: 54px;
$default-text-input-height: 1.5rem;
$default-icon-size: 16px;
$help-icon-size: 20px;
$icon-size-button: 24px;
$selected-element-box-shadow: 0 2px 8px 2px $line-grey;
$header-height: 44px;
$header-big-height: 84px;
$footer-height: 30px;
$column-header-height: 38px;
$button-bar-height: 49px;
$hamburger-menu-spacing: 25px;
$monaco-editor-ruler-width: 30px;

$focus-outline-offset: 2px;
$focus-outline-width: 2px;

// was set originally by using a mat-toolbar, which hardcoded 64px as the height
$dialog-header-height: 64px;

/* validation circle */
$validation-circle-standard-size: 12px;
$validation-circle-border-size: 1px;
/* end of validation circle */

/* Media variables*/
$big-screen-min-width: 1280px;
$big-screen-min-height: 805px;
$big-screen: "(min-width: #{$big-screen-min-width}) and (min-height: #{$big-screen-min-height})";
/* End of media variables */

$adm4-mat-table-icon-size: 18px;
// mat-button height from before M3
$adm4-mat-button-height: 36px;

// form-field height taken over from before the MDC migration
$mat-form-field-height: 25px;

// mat components sizes: these come from actually rendered material components
$mat-menu-item-height: 48px;
// defined by the mat-radio components
$mat-radio-height: 36px;

$mat-border-radius: 4px;

$mat-icon-button-padding: 12px;
// end of mat components sizes

$deployment_selection_list_left_margin: 22px;  // default_spacing_multiplier(2.75)
