// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@import './fonts';
@import './colors';

/*
// Originally generated palette, from the color #168CA9
$original-primary-palette: (
    0: #000000,
    10: #001f28,   // hsl(193.5  100%,     7.84%)
    20: #003543,   // hsl(192.54, 100%,   13.14%)
    25: #004151,   // hsl(191.85, 100%,   15.88%)
    30: #004e60,   // hsl(191.25, 100%,   18.82%)
    35: #005a6f,   // hsl(191.35, 100%,   21.76%)
    40: #00677e,   // hsl(190.95, 100%,   24.71%) // this is picked up as the color for many components
    50: #00829e,   // hsl(190.63, 100%,   30.98%)
    60: #009ebf,   // hsl(190.37, 100%,   37.45%)
    70: #35b9dd,   // hsl(192.86, 71.19%, 53.73%)
    80: #5bd5fa,   // hsl(193.96, 94.08%, 66.86%)
    90: #b5ebff,   // hsl(196.22, 100%,   85.49%)
    95: #dcf5ff,   // hsl(197.14, 100%,   93.14%)
    98: #f2fbff,   // hsl(198.46, 100%,   97.45%)
    99: #f9fdff,   // hsl(200,    100%,   98.82%)
    100: #ffffff,
);
//*/

/*
There are some problems with the originally generated palette above
- It's the color `40` that is picked up by many components, but it's darker than our brand color $nevis-green-c1: #168CA9;
- The lighter colors, ie. `70` and above, are somehow more and more blueish, see the hsl values.

So the new palette is generated with based on the following:
- uses the same `h` value of `192` and `s` value of `77` as the original brand color, so it does not turn into blue on the higher end, and has the same saturation
- the `l` value is set up in a way where the `40` is exactly the brand color, and the rest is distributed evenly
*/

$_palettes: (
  primary: (
    0: #000000,    // hsl(192,77%,0%)
    10: #06252d,    // hsl(192,77%,10%)
    20: #0b4756,    // hsl(192,77%,19%)
    25: #0e596c,    // hsl(192,77%,24%)
    30: #10687e,    // hsl(192,77%,28%)
    35: #137b95,    // hsl(192,77%,33%)
    40: #168CA9,    // hsl(192,77%,37.5%)
    50: #1cb1d6,    // hsl(192,77%,47.5%)
    60: #3fc5e6,    // hsl(192,77%,57.5%)
    70: #6ad2ec,    // hsl(192,77%,67%)
    80: #99e0f2,    // hsl(192,77%,77.5%)
    90: #c9eff8,    // hsl(192,77%,88%)
    95: #dbf4fa,    // hsl(192,77%,92%)
    98: #edfafd,    // hsl(192,77%,96%)
    99: #f6fcfe,    // hsl(192,77%,98%)
    100: #ffffff,    // hsl(192,77%,100%)
  ),
  // generated from the secondary color #75C3D7, which is hsl(192.24, 55.06%, 65.1%)
  secondary: (
    0: #000000,
    10: #001f26,
    20: #003640,
    25: #00424e,
    30: #004e5c,
    35: #005b6a,
    40: #006879,
    50: #008398,
    60: #009fb8,
    70: #2cbad7,
    80: #55d6f3,
    90: #abedff,
    95: #d8f6ff,
    98: #f0fbff,
    99: #f8fdff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #161937,
    20: #2b2e4d,
    25: #363959,
    30: #424465,
    35: #4d5071,
    40: #595c7e,
    50: #727498,
    60: #8c8eb3,
    70: #a6a8ce,
    80: #c2c3eb,
    90: #e0e0ff,
    95: #f1efff,
    98: #fbf8ff,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #191c1d,
    20: #2e3132,
    25: #393c3d,
    30: #444749,
    35: #505354,
    40: #5c5f60,
    50: #757779,
    60: #8f9192,
    70: #a9abad,
    80: #c5c7c8,
    90: #e1e3e4,
    95: #eff1f2,
    98: #f8f9fb,
    99: #fbfcfe,
    100: #ffffff,
    4: #0c0f10,
    6: #111415,
    12: #1d2021,
    17: #272a2c,
    22: #323537,
    24: #373a3b,
    87: #d8dadc,
    92: #e7e8ea,
    94: #eceeef,
    96: #f2f4f5,
  ),
  neutral-variant: (
    0: #000000,
    10: #151d20,
    20: #293235,
    25: #343d40,
    30: #40484b,
    35: #4b5457,
    40: #576063,
    50: #70787c,
    60: #899296,
    70: #a4adb0,
    80: #bfc8cc,
    90: #dbe4e8,
    95: #eaf2f6,
    98: #f2fbff,
    99: #f9fdff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$nevis-light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
  typography: (
    plain-family: $font_family-default,
    brand-family: $font_family-default,
    bold-weight: 800,
    medium-weight: 500,
    regular-weight: 400,
  ),
  density: (
    scale: 0,
  )
));
