@import "src/app/common/styles/colors";
@import "src/app/common/styles/functions";
@import "src/app/common/styles/fonts";

/**
 * Panel classes are used for material containers for example modals or select inputs
 * Only import in global scss once
 */

// Menu panel classes
.overflow-menu {
  .mat-mdc-menu-content {
    width: inherit;
    padding: 0;
  }
}

.green-border-menu {
  .mat-mdc-menu-content {
    border: $nevis-green-c1 solid 1px;
    background: $screen-white;
    border-radius: 3px;
    padding: 0;
  }
}

.hamburger-menu {
  &.mat-mdc-menu-panel {
    margin-top: 7px; // menu appears right under icon when we click it, this is finetuned value to make menu appear under header so that its borders start from header border
    &.mat-mdc-menu-panel {
      max-width: 50vw;
      max-height: 75vh;
      border-radius: 0;
    }
  }

  .mat-mdc-menu-content {
    &.mat-mdc-menu-content {
      border-bottom: $nevis-green-c1 solid 2px;
      border-left: $nevis-green-c1 solid 2px;
      border-right: $nevis-green-c1 solid 2px;
      background-color: $screen-white;
      padding: 0;
    }
  }
}

.split-button-menu {
  border: 1px solid $focus-color-yellow-off;
  border-radius: 5px;
  min-height: default_spacing_multiplier(5) !important;

  .mat-mdc-menu-content {
    &.mat-mdc-menu-content {
      padding: 0;

      .mat-mdc-menu-item {
        background: $screen-white;

        &:hover {
          background: $white-c0;
        }
      }
    }
  }
}

.mat-mdc-menu-panel {
  &.min-width-250 {
    min-width: 250px !important;
  }
}
// end of menu panel classes

// inventory and project dropdown panel classes
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel.project-inventory-dropdown {
  border: $nevis-green-c1 solid 1px;
  background: $screen-white;
  border-radius: 3px;
  max-height: 80vh !important;

  .mat-mdc-option {
    --mat-option-hover-state-layer-color: #{$white-c0};

    .mdc-list-item__primary-text {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
// end of inventory and project dropdown panel classes

// markdown rendered
// TODO NEVISADMV4-8713 move this to the common style
.markdown-rendered {
  background-color: $screen-white;
  padding: default_spacing_multiplier(2);

  color: $menu-icon-grey;

  h1 {
    font: $font-docs-h1;
  }

  h2 {
    font: $font-docs-h2;
  }

  h3 {
    font: $font-docs-h3;
  }

  h4 {
    font: $font-docs-h4;
  }

  h5 {
    font: $font-docs-h5;
  }

  p {
    font: $font-default-regular;
  }

  // so that the last element doesn't have additional space after it
  *:last-child {
    margin-bottom: 0;
  }

  blockquote {
    color: $menu-icon-grey;
    padding-left: default_spacing_multiplier(2);
    border-left: solid 2px $light-grey;
  }

  pre {
    margin: default_spacing_multiplier(1) 0;
    padding: default_spacing_multiplier(1);
    font: $font-code-regular;
    background-color: $screen-white;
    border-radius: $default-input-border-radius;
    outline: 1px solid $disabled-grey;
  }

  $table-border: 1px solid $disabled-grey;
  table {
    margin-bottom: default_spacing_multiplier(2);

    background-color: $background-grey;

    border-collapse: separate;  // needed for the rounded border
    border-spacing: 0;          // needed for the rounded border
    border: $table-border;
    border-radius: $default-input-border-radius;

    th, td {
      padding: default_spacing_multiplier(1);
    }

    td:has(+ td),
    th:has(+ th) {
      border-right: $table-border;
    }

    tr:has(+ tr) td,
    th {
      border-bottom: $table-border;
    }

    tbody tr:nth-child(odd) {
      background-color: white;
    }

    tbody tr:hover {
      background-color: $nevis-green-ligtest-c4;
    }
  }
}
// end of markdown rendered

// mat-card versions
mat-card.mat-mdc-card {
  mat-card-content {
    &.padding-small {
      padding: default_spacing_multiplier(1);
    }

    &.deployment-card {
      padding-bottom: 0;
    }
  }
}
// end of mat-card versions

// cdk-virtual-scroll adjustments
cdk-virtual-scroll-viewport.cdk-virtual-scroll-width-100 .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}
// end of cdk-virtual-scroll adjustments

// mat-dialog content
/**
 * Use this class instead of importing `modal-window.scss` into your component.
 */
.adm4-mat-dialog {
  .content-container {
    padding: default_spacing_multiplier(3) default_spacing_multiplier(4) default_spacing_multiplier(4) default_spacing_multiplier(4);
  }

  .input-label {
    margin-bottom: default_spacing_multiplier(1);
    font: $font-l-regular;
  }

  .mat-mdc-dialog-actions {
    & > *:not(:last-child) {
      margin-right: default_spacing_multiplier(3);
    }
  }
}
// end of mat-dialog content
