@use '@angular/material' as mat;
@use './mat-m3-nevis-theme' as mat-m3-nevis-theme;

// this is for bootstrap, initially the base font size was 12px,
// and this scaling was added when that was changed to 16px
// in order for the bootstrap stuff to look the same as with the 12px base font size
$font-size-base: 0.75rem;
@import "bootstrap-custom";

@import "fonts_families";
@import "fonts";
@import "colors";
@import "../../notification/notification-toaster";
@import "buttons";
@import "inputs";
@import "icons";
@import "util-classes";
@import "form-validation";
@import "dialogs";
@import "panel-classes";
@import "inline-help";
@import "global-text-classes";
@import "monaco-editor";
@import "marketplace";
@import "deployment";
@import "tooltip";
@import "css_components";
@import "mat-table";
@import "splitpane";
@import "inventory-global-styles";

.DEBUG-blue * {
  outline: 1px solid rgba(0 100 255 / 0.5);
}

.DEBUG-red * {
  outline: 1px solid rgba(255 0 0 / 0.5);
}

@include mat.core();

// https://material.angular.io/guide/material-2-theming#how-to-migrate-an-app-from-material-2-to-material-3
// the upgrade guide recommends not adding this to the root, but an element
html {
  @include mat.core-theme(mat-m3-nevis-theme.$nevis-light-theme);

  @include mat.all-component-themes(mat-m3-nevis-theme.$nevis-light-theme);

  -webkit-font-smoothing: initial !important;

  // active mat-tab label text color
  --mat-tab-header-active-label-text-color: #{$nevis-green-c1};
  // override the blue-ish background color of the dialogs
  --mdc-dialog-container-color: #{$white-c0};
  --mat-dialog-container-elevation-shadow: var(--mat-app-elevation-shadow-level-16);

  // overriding the base text color to the one we use
  --mat-app-text-color: #{$screen-black};
  --mat-option-label-text-color: #{$screen-black};
  --mat-option-selected-state-layer-color: #{$nevis-green-light-c2};

  /* The actual value of this was neutral-50, but that was too dark compared to the previous one, which was neutral-80.
     So here added our color, which is somewhat lighter than before.
   */
  --mat-table-row-item-outline-color: #{$light-grey};

  --mat-expansion-header-text-size: #{$font-size-l};
  --mat-expansion-container-text-size: #{$font-size-l};
  --mat-expansion-container-background-color: #{$white-c0};

  --mdc-elevated-card-container-color: #{$white-c0};

  --mat-radio-label-text-size: #{$font-size-normal};
  --mat-radio-label-text-color: #{$screen-black};
  --mat-radio-label-text-weight: #{$font-weight-medium};

  --mdc-dialog-supporting-text-size: #{$font-size-normal};

  --mat-select-container-elevation-shadow: var(--mat-app-elevation-shadow-level-8);

  --mat-tree-node-text-size: #{$font-size-l};
}

.secondary-slide-toggle {
  @include mat.slide-toggle-color(mat-m3-nevis-theme.$nevis-light-theme, $color-variant: secondary);
}

/*
 The mixin `mat.checkbox-color` emits CSS variables only, like other component mixins.
 For other components, the variables to set their colors are defined on the `<html>`, so adding the mixin's output
 with a class overrides the default color.<br/>
 But in the case of the `mat-checkbox`, the color variables are originally applied
 to the component itself with the selector `mat-checkbox.mat-mdc-checkbox`,
 so the override has to be applied with the same specificity.
 */
mat-checkbox.mat-mdc-checkbox {
  @include mat.checkbox-color(mat-m3-nevis-theme.$nevis-light-theme, $color-variant: primary);
}

/* Global Style Reset */
/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
}

html, body {
  font-weight: $font-weight-regular;
  font-family: $font_family-default;
  font-size: $font-size-global-default;
  height: 100%;
  color: $screen-black;
}

// removing the margin from the label, originally added by bootstrap's reboot
label {
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

button, input, optgroup, select, textarea {
  font-family: $font_family-default !important;
}

textarea.code {
  font-family: $font-family-code !important;
}

a {
  @include nevis-green-link();

  &:not([href]):not([tabindex]) {
    @include nevis-green-link();
  }

  &.link-with-icon {
    display: inline-flex;
    align-items: flex-end;

    &:hover {
      text-decoration: none !important;

      span {
        text-decoration: underline;
      }
    }

    .material-icons {
      @include mat-icon-size(16px);
      margin-left: default_spacing_multiplier(0.5);
    }
  }
}

a.disabled {
  pointer-events: none;
  cursor: pointer;
  color: $disabled-grey;
}

.material-icons {
  @include material-icon();
}

// mat-icon repeated twice to make it important to override original styles and not to use !important
.mat-icon.mat-icon {
  // fixes layout of svg mat-icons
  display: inline-flex;
}

.highlight {
  font-weight: bold;
  color: $black;
}

.greyed-out {
  color: $menu-icon-grey;
}

.link-button {
  cursor: pointer;
  text-decoration: underline;
  color: $nevis-green-c1;
}

/* Used by LoadingIndicatorDirective to indicate if content is loading. */
.loading {
  /* Reduce opacity when the content of the element is loading. */
  opacity: 0.1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.disabled-opacity {
  opacity: 0.5;
}

.blurred-background {
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.32);
}

// material overrides
mat-dialog-container {
  font-size: $font-size-normal;
}
// END of material overrides
