@import "mixins";
@import "colors";
@import "functions";
@import "variables";

.full-width {
  width: 100%;
}

// takes full height of the parent
.full-height {
  height: 100%;
}

.full-height-flex {
  @extend .full-height;
  display: flex;
  flex-direction: column;

  // takes remaining full height of flex parent
  .remaining-space-flex-content-wrapper {
    position: relative;
    flex-grow: 1;

    // makes sure that content must be within remaining full space space
    .remaining-space-flex-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.d-contents {
    display: contents !important;
}

.flex-center-xy {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.inset-0 {
  inset: 0 !important;
}

.isolation-isolate {
  isolation: isolate;
}

.z-index--1 {
  z-index: -1;
}

// gap utils missing in bootstrap 4.6, probably because 4.6 is old
.gap-1 {
  gap: default_spacing_multiplier(1);
}
.gap-2 {
  gap: default_spacing_multiplier(2);
}
.gap-3 {
  gap: default_spacing_multiplier(3);
}

.validation-message-container {
  min-height: 16px;
  margin: default_spacing_multiplier(0.5) 0 default_spacing_multiplier(1.5);
}

.line-indicatable {
  @include line-indicatable-with-box-shadow($nevis-green-c1, $nevis-green-c1);
}

.line-indicatable-validated {
  @include line-indicatable-selectable($nevis-green-c1);

  &.has-error {
    @include left-line-indicator($error-red);
  }

  &.has-warning {
    @include left-line-indicator($warning-orange);
  }

  &.has-advice {
    @include left-line-indicator($info-blue);
  }
}

.left-icon {
  margin-right: default_spacing_multiplier(1);
}

.text-ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/**
 * Usage: add the `scroll-shadows` class to the element whose child elements scroll.
 * Also set the variable `--scroll-shadow-bg` to the background color of the children, eg. `--scroll-shadow-bg: #{$screen-white-rgb};`,
 * otherwise the default white background will be applied, as this is basically a trick with the background.<br/>
 * Source: https://css-tricks.com/scroll-shadows-pure-css-parallax-game-back-on/
 */
.scroll-shadows {
  background:
          /* Shadow Cover TOP */
          linear-gradient(
                          var(--scroll-shadow-bg, white) 30%,
                          var(--scroll-shadow-bg, white)
          ) center top,

            /* Shadow Cover BOTTOM */
          linear-gradient(
                          var(--scroll-shadow-bg, white) 0%,
                          var(--scroll-shadow-bg, white) 70%
          ) center bottom,

            /* Shadow TOP */
          radial-gradient(
                          farthest-side at 50% 0,
                          rgba(0, 0, 0, 0.2),
                          rgba(0, 0, 0, 0)
          ) center top,

            /* Shadow BOTTOM */
          radial-gradient(
                          farthest-side at 50% 100%,
                          rgba(0, 0, 0, 0.2),
                          rgba(0, 0, 0, 0)
          ) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 10px, 100% 10px, 100% 4px, 100% 4px;
  background-attachment: local, local, scroll, scroll;
}

.color-green-c1 {
  color: $nevis-green-c1;
}

.color-info {
  color: $info-blue;
}

.color-success {
  color: $validation-green;
}

.color-warning {
  color: $warning-orange;
}

.color-error {
  color: $error-red;
}

.color-screen-black {
  color: $screen-black;
}

.bg-screen-white {
  background-color: $screen-white;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.cursor-wait {
  cursor: wait !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

mat-icon.mat-icon.size-16 {
  @include mat-icon-size(16px);
}

mat-icon.mat-icon.size-20 {
  @include mat-icon-size(20px);
}

.mdc-list-item.list-item-shrink {
  .mdc-list-item__primary-text {
    min-width: 0;
  }
}

.font-size-normal {
    font-size: $font-size-normal !important;
}

.mat-mdc-menu-item.menu-item-overflow-x-hidden .mat-mdc-menu-item-text {
  overflow-x: hidden;
}

// aligns the element with the label of the mat-radio-button by adding left margin
mat-radio-button + .adm4-align-with-radio-label {
  // value calculated from the radio button, which has significant left padding
  margin-left: 34px;
}

.cdk-overlay-pane .mat-overlay-mt-10 {
  margin-top: 10px;
}

.spinner-white.mat-mdc-progress-spinner circle, .spinner-white.mat-mdc-spinner circle {
  stroke: $white-c0;
}


button.mat-mdc-icon-button.size-24 {
  --mdc-icon-button-state-layer-size: 24px;
  margin: 0;
  padding: 0;
}

mat-tab-group {
  &.mat-tabs-full-height {
    .mat-mdc-tab-body-wrapper {
      height: 100%;
    }
  }

  &.tab-content-overflow-y-hidden {
    .mat-mdc-tab-body-content {
      overflow-y: hidden;
    }
  }
}

.font-smoothing-default {
  -webkit-font-smoothing: initial !important;
}

ngx-monaco-editor,
ngx-monaco-diff-editor {
  &.monaco-height-fix {
    position: relative;

    .editor-container {
      height: 100%;
    }
  }
}
