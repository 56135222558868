@import "colors";
@import "fonts";
@import "functions";
@import "variables";

.admn4-input {
  font: $font-default-regular;
  border: 1px solid $light-grey;

  &.form-control {
    box-shadow: none;
    outline: 0;
  }

  &:focus:not([disabled]) {
    border-color: $active-blue;
  }

  &:not(:placeholder-shown):not(:focus) {
    border-color: $inactive-grey;
  }

  &[disabled], &[readonly] {
    cursor: default;
    background-color: $background-grey;
  }
}

.admn4-text-input {
  @extend .admn4-input;
  height: $default-text-input-height;
  padding: 0 default_spacing_multiplier(1);
}

// add this class to text input that masks for a file input
.admn4-fake-file-input {
  @extend .admn4-input;
  height: $default-text-input-height;
  padding: 0 default_spacing_multiplier(1);
  cursor: pointer;

  &[readonly] {
    cursor: pointer;
    background-color: $white-c0;
  }
}

.admn4-input-group-with-icon {
  display: flex;

  input {
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .input-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: default_spacing_multiplier(3);
    padding-left: default_spacing_multiplier(3);
    border-radius: 0 $default-input-border-radius $default-input-border-radius 0;
    white-space: nowrap;
  }
}

.admn4-textarea-input {
  @extend .admn4-input;
  padding: default_spacing_multiplier(1);
}

mat-checkbox.mat-mdc-checkbox {
  // this is the same value that is added inside the checkbox as padding, just negative, to counter that padding
  margin: calc((var(--mdc-checkbox-state-layer-size) - 18px) / -2);

  --mdc-checkbox-selected-focus-icon-color: #{$focus-color-blue-on};
  --mdc-checkbox-selected-hover-icon-color: #{$focus-color-blue-on};
  --mdc-checkbox-selected-icon-color: #{$focus-color-blue-on};
  --mdc-checkbox-selected-pressed-icon-color: #{$focus-color-blue-on};
  --mdc-checkbox-selected-focus-state-layer-color: #{$focus-color-blue-on};
  --mdc-checkbox-selected-hover-state-layer-color: #{$focus-color-blue-on};
  --mdc-checkbox-selected-pressed-state-layer-color: #{$focus-color-blue-on};

  --mdc-checkbox-unselected-icon-color: #{$light-grey};
  --mdc-checkbox-unselected-hover-icon-color: #{$focus-color-blue-on};
  --mdc-checkbox-unselected-focus-icon-color: #{$focus-color-blue-on};

  --mdc-checkbox-disabled-unselected-icon-color: #{$disabled-grey};
  --mdc-checkbox-disabled-selected-icon-color: #{$disabled-grey};

  .mdc-checkbox__background {
    border-radius: $default-input-border-radius;
    border-width: 1px;
    // the padding is only visible with the indeterminate state's dash, and not with the selected checkmark,
    // because the checkmark is absolute positioned in the `.mdc-checkbox__background`,
    // so the selected state has the additional border below
    padding: 1px;
  }

  &.mat-mdc-checkbox-checked .mdc-checkbox__background {
    // this border acts as a padding for the checked state, because the checkmark is absolute positioned inside `.mdc-checkbox__background`
    // so padding does not effect it
    border-width: 2px;
  }

  .checkbox-label-sm {
    font: $font-default-regular;
  }
}

.checkbox-label {
  display: flex;
  align-items: center;

  .mat-mdc-checkbox {
    margin-right: default_spacing_multiplier(1);
  }

  &:has(mat-checkbox.mat-mdc-checkbox-disabled) {
    color: $disabled-grey;
  }
}

.mat-mdc-checkbox label {
  margin: 0;
}

mat-radio-button.mat-mdc-radio-button.left-align {
  // for left-aligning the radio, countering the built-in padding
  margin-left: -8px;
}


/* `<mat-form-field>`s' `subscriptSizing` property is permanently overridden with `MatFormFieldDynamicSubscriptSizingDirective`.
 * Use `[allowSubscriptSizing]` on the `<mat-form-field>` to allow changing the subscript sizing.
 */
mat-form-field.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
    background-color: unset;
  }

  &.mat-mdc-form-field-type-mat-select:not(.mdc-text-field--outlined) {
    .mat-mdc-form-field-infix {
      padding-bottom: 0;
      padding-top: 0;
      min-height: $mat-form-field-height;
    }

    &.width-100 .mat-mdc-form-field-infix {
      width: 100%;
    }

    &.hide-underline .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      padding-left: 0;
      padding-right: default_spacing_multiplier(0.5);
    }
  }

  &.mat-mdc-form-field-type-mat-chip-grid:not(.mdc-text-field--textarea) {
    .mat-mdc-text-field-wrapper {
      padding-left: 0;
      padding-right: default_spacing_multiplier(0.5);
    }
    .mat-mdc-chip-grid {
      margin-left: default_spacing_multiplier(1);
    }

    .mat-mdc-form-field-infix {
      padding-bottom: default_spacing_multiplier(1);
      padding-top: default_spacing_multiplier(1);
    }
  }

  &.mat-mdc-form-field-type-mat-input:not(.mdc-text-field--textarea) {
    .mat-mdc-text-field-wrapper {
      padding-left: 0;
      padding-right: default_spacing_multiplier(0.5);
      height: $mat-form-field-height;
      max-height: $mat-form-field-height;
    }
    .mat-mdc-form-field-infix {
      padding-bottom: 0;
      padding-top: 0;
      min-height: $mat-form-field-height;
    }
  }
}

.mat-mdc-select {
  --mat-select-trigger-text-size: 12px; // the value comes from before the MDC migration

  // allow selecting text from disabled material selects
  &.mat-mdc-select-disabled .mat-mdc-select-trigger {
    user-select: text;
  }

  &.placeholder-black {
  --mat-select-placeholder-text-color: #{$screen-black};
  }
}

.cdk-overlay-container div.mat-mdc-select-panel {
  // this padding would be around the `adm4-searchable-dropdown-input` and is transparent,
  // so through the padding part, the options would be visible behind the search
  padding-top: 0;
  padding-bottom: 0;
}

mat-option.mat-mdc-option {
  min-height: 36px; // the value comes from before the MDC migration

  .mdc-list-item__primary-text {
    font-size: 12px; // the value comes from before the MDC migration
  }

  &.option-large .mdc-list-item__primary-text {
    font: $font-xl-regular;
  }
}

button.mat-mdc-button.touch-target-contain {
  .mat-mdc-button-touch-target {
    inset: 0;
    transform: none;
    height: unset;
  }
}

button.mat-mdc-button.icon-flex mat-icon {
  display: flex;
}
