@import "src/app/common/styles/functions";
@import "src/app/common/styles/colors";
@import "src/app/common/styles/mixins";
@import "src/app/common/styles/variables";

/**
 * Usage: add `.adm4-mat-table` somewhere above your table to apply these styles.
 * This supersedes `nevisadmin-app-web/src/app/common/styles/component-specific/mat-table.scss`.
 * If you find a usage of that, please migrate it to this class.
 */
mat-table.adm4-mat-table,
[mat-table].adm4-mat-table {
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-header-headline-color: #{$screen-black};
  --mat-table-background-color: #{$white-c0};

  mat-cell, mat-header-cell {
    padding: 0 default_spacing_multiplier(2);
  }

  &.no-shadow {
    background-color: white;
  }

  .mat-mdc-header-row {
    height: default_spacing_multiplier(6);
    min-height: default_spacing_multiplier(6);
  }

  .mat-mdc-row {
    height: default_spacing_multiplier(5);
    min-height: default_spacing_multiplier(5);

    &:hover {
      background-color: $screen-white;
    }
  }

  .mat-column-checkbox {
    display: flex;
    justify-content: center;
    max-width: 100px;
  }

  .mat-column-inactive {
    color: $inactive-grey;
  }

  .mat-mdc-cell mat-icon {
    font-size: $adm4-mat-table-icon-size;
    height: $adm4-mat-table-icon-size;
    width: $adm4-mat-table-icon-size;
  }

  mat-cell:first-of-type,
  mat-footer-cell:first-of-type,
  mat-header-cell:first-of-type {
    padding-left: default_spacing_multiplier(2);
  }

  &.align-rows-start mat-cell {
    align-items: flex-start;
  }
}

.certificate-item-row {
  height: 100% !important;
  align-items: flex-start;

  &.warning {
    background-color: rgba($warning-orange, 0.2);

    &:hover {
      background-color: rgba($warning-orange, 0.3) !important;
    }
  }

  &.error {
    background-color: rgba($error-red, 0.2);

    &:hover {
      background-color: rgba($error-red, 0.3) !important;
    }
  }

  mat-cell {
    // otherwise they inherit the row's bg, which messes with the opacity of the unused ones
    background: unset;
  }

  .single-line-item {
    padding: default_spacing_multiplier(1) 0;
    display: flex;
    align-items: baseline;
    flex: 1;
    width: 100%;

    &.multi-line-content {
      padding: 0;
      display: block;
    }

    &.serial-wrapper {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }

    .cert-expiry-icon {
      margin-left: default_spacing_multiplier(1);
      transform: translateY(3px);
      cursor: default;
    }
  }

  mat-cell.action-cell .single-line-item {
    display: flex;
    justify-content: center;
  }

  .state-used {
    color: $validation-green;
  }

  .state-prepared {
    color: $info-blue;
  }
}
