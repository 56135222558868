// Nevis colors
$nevis-green-c1: #168CA9; // Nevis Blue, hsl(191.84, 76.96%, 37.45%)
$nevis-green-light-c2: #75C3D7; // Nevis Blue light

//asked by roel NEVISADMV4-1954
$nevis-green-ligtest-c4: #deeef0;

// end of Nevis colors

// Neutral colors
$white-c0: #ffffff;
$black: #000000;
$screen-white: #FAFAFA;
$screen-white-rgb: rgb(250 250 250);
$screen-black: #46494B;
$background-grey: #f9f9f9;
$transparent: rgba(0,0,0,0);
// end of Neutral colors

// Interaction colors
$focus-color-blue-on: #236ACB;
$focus-color-blue-off: #9FC3EF;
$focus-color-yellow-on: #C8A900;
$focus-color-yellow-off: #FFD700;
$active-blue: #3495DD;
$inactive-grey: #8B939D;
$light-grey: #D8D8D8;
$line-grey: #EAEAEA;
$menu-icon-grey: #656D78;
$disabled-grey: #D3D3D3;
// end of Interaction colors

// Issue and Feedback colors
$error-red: #DC4250;
$light-error-red: #FBECED;
$warning-orange: #F48D02;
$light-warning-orange: #FEF2D9;
$info-blue: #5289B6;
$light-info-blue: #CCE8F6;
$validation-green: #539B00;
$light-validation-green: #DCEACB;
// end of Issue and Feedback colors

// Inventory colors
$inventory-default: $screen-white;
$inventory-red: #E53935;
$inventory-yellow: #FDD835;
$inventory-green: #66BB6A;
$inventory-purple: #8E24AA;
$inventory-pink: #F06292;
$inventory-brown: #D2B48C;
$inventory-blue: #1976D2;

$inventory-red-transparent: rgba(255, 69, 0, 0.1);
$inventory-yellow-transparent: rgba(255, 215, 0, 0.1);
$inventory-green-transparent: rgba(144, 238, 144, 0.1);
$inventory-purple-transparent: rgba(221, 160, 221, 0.1);
$inventory-brown-transparent: rgba(210, 180, 140, 0.2);
$inventory-blue-transparent: rgba(0, 255, 255, 0.05);
// end of Inventory colors
